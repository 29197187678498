
import { computed, defineComponent, reactive } from "vue";
import { useHead } from "@vueuse/head";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

import CModal from "@/components/Modal.vue";
import PDataTable from "primevue/datatable";
import PColumn from "primevue/column";
import PProgressBar from "primevue/progressbar";

import { addMoneyMask, formatCPFToLGPD, formatDate } from "@/libs/utils";

const VCashierTransactionGuides = defineComponent({
  name: "VCashierTransactionGuides",
  components: { CModal, PDataTable, PColumn, PProgressBar },
  setup() {
    useHead({ title: "Resumo das guias eletrônicas | obmed" });

    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const guideIds = route.params._id.toString().split("-");
    const loading = reactive({ guides: false });
    const guides = computed(() => store.state.cashier.transaction.guides);

    function handleClose() {
      router.back();
    }

    async function getCashierTransactionGuides() {
      loading.guides = true;
      await store.dispatch("getCashierTransactionGuides", { guideIds });
      loading.guides = false;
    }

    getCashierTransactionGuides();

    return {
      ...{ guides, loading },
      ...{ handleClose },
      ...{ addMoneyMask, formatDate, formatCPFToLGPD },
    };
  },
});

export default VCashierTransactionGuides;
